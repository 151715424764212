import { TFunction } from 'i18next';

import {
  getClubTypeOptions,
  getPreferencesOptions,
  getProfessionOptions,
} from './optionsList';

import { FormFieldsProps, FormInfoProps, FormValues } from '../types';

export const getFormInformation = (
  t: TFunction,
  formName: string
): FormFieldsProps => {
  const formField: FormInfoProps = {
    profileForm: {
      firstName: {
        name: 'firstName',
        label: t('edit-profile-details.form.given-name-label', 'First name'),
        required: true,
        description: t(
          'edit-profile-details.form.first-name-info',
          'Latin characters required'
        ),
      },
      lastName: {
        name: 'lastName',
        label: t('edit-profile-details.form.family-name-label', 'Last name'),
        required: true,
        description: t(
          'edit-profile-details.form.last-name-info',
          'Latin characters required'
        ),
      },
    },
    aboutMyReferral: {
      preference: {
        name: 'club_preference',
        label: t(
          'join-form-referral.field_preference_label',
          'Club assignment preference'
        ),
        options: getPreferencesOptions(t),
      },
      clubType: {
        name: 'club_type',
        label: t('join-form-referral.field_club_type_label', 'Club type'),
        options: getClubTypeOptions(t),
      },
      firstName: {
        name: 'firstName',
        label: t('join-form-referral.field_first_name_label', 'First name'),
        required: true,
      },
      lastName: {
        name: 'lastName',
        label: t('join-form-referral.field_last_name_label', 'Last name'),
        required: true,
      },
      email: {
        name: 'email',
        label: t('join-form-referral.field_email_label', 'Email'),
        required: true,
      },
      phoneCode: {
        name: 'phone_code',
        label: t('join-form-referral.field_phone_code_label', 'Country code'),
        required: false,
      },
      phoneNumber: {
        name: 'phone_number',
        label: t('join-form-referral.field_phone_number_label', 'Phone'),
        required: false,
      },
      gender: {
        name: 'gender',
        label: t('join-form-referral.field_gender_label', 'Gender'),
        required: false,
      },
      genderDescription: {
        name: 'genderDescription',
        label: '',
        required: false,
      },
      ageCertification: {
        name: 'ageCertification',
        label: t(
          'join-form-referral.field_age_certification_label',
          'Age certification'
        ),
        required: true,
        description: t(
          'join-form-referral.field_age_certification_description',
          'I certify that this person is at least 18 years old.'
        ),
      },
      profession: {
        name: 'profession',
        label: t(
          'join-form-referral.field_profession_label',
          'What is their profession?'
        ),
        required: false,
        options: getProfessionOptions(t),
      },
      additionalComments: {
        name: 'additional_comments',
        label: t(
          'join-form-referral.field_additional_comments_label',
          'Additional comments'
        ),
        required: false,
      },
    },
  };
  return formField[formName];
};

export const getFormInitialValue = (formName: string = '') => {
  const formInitialValue: FormValues = {
    aboutMyReferral: {
      club_preference: 'own',
      club_type: 'rotary',
      firstName: '',
      lastName: '',
      email: '',
      phone_number: '',
      genderDescription: '',
      ageCertification: false,
    },
  };
  return formInitialValue[formName] || {};
};
