import React from 'react';
import { useTranslation } from '@external/react-i18next';
import BaseModal from '.';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
}
const LeaveFormConfirmationModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  onConfirm,
}) => {
  const { t } = useTranslation();
  return (
    <BaseModal
      isOpen={isOpen}
      closeModal={closeModal}
      onConfirm={onConfirm}
      contentLabel={t(
        'global.modals.confirm-leave-form.label',
        'Confirm leaving form'
      )}
      title={t(
        'global.modals.confirm-leave-form.title',
        'Are you sure you want to leave this page?'
      )}
      text={t(
        'global.modals.confirm-leave-form.subtitle',
        'Changes have not been saved.'
      )}
      confirmButtonLabel={t('global.modals.confirm-leave-form.cancel', 'Leave')}
      cancelButtonLabel={t('global.modals.confirm-leave-form.confirm', 'Stay')}
    />
  );
};

export default LeaveFormConfirmationModal;
