import React from 'react';

import TextField from '@components/Formik/TextField';

import { getFormInformation } from '@domain/leads/referral/formInfo';
import { FormFieldsProps } from '@domain/leads/types';

import { useTranslation } from '@external/react-i18next';

const FirstLastNameForm: React.FC = () => {
  const { t } = useTranslation();

  const fieldProps: FormFieldsProps = getFormInformation(t, 'profileForm');
  const { firstName, lastName } = fieldProps;

  return (
    <>
      <div className="mobile:mb-6">
        <TextField {...firstName} />
      </div>
      <div className="mobile:mb-6">
        <TextField {...lastName} />
      </div>
    </>
  );
};
export default FirstLastNameForm;
