import React from 'react';

import classNames from 'classnames';
import { useFormikContext } from 'formik';

import TextField from '@components/Formik/TextField';
import Icon from '@components/Icon';

import CountryFlagSelect from './Select/CountryFlagSelect';

import { useTranslation } from '@external/react-i18next';

type Props = {
  suffix: string;
  labels: {
    phoneCode: string;
    phoneNumber: string;
    phoneExtension?: string;
  };
  required?: boolean;
  isCountryFlagRequired?: boolean;
  displayClearButton?: boolean;
  isClearButtonDisabled?: boolean;
  clearButtonStyle?: string;
};

const PhoneInput: React.FC<Props> = ({
  suffix,
  labels,
  required,
  isCountryFlagRequired,
  displayClearButton,
  isClearButtonDisabled,
  clearButtonStyle,
}) => {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  const clearFields = () => {
    setFieldValue(`${suffix}`, '');
    setFieldValue(`${suffix}.number`, '');
    setFieldValue(`${suffix}.extension`, '');
  };

  return (
    <>
      {displayClearButton && (
        <div className="float-right">
          <button
            type="button"
            disabled={isClearButtonDisabled}
            onClick={clearFields}
            className={classNames(
              `edit-link mr-2 flex items-center inline-block h5 alternate ${clearButtonStyle}`,
              {
                'text-gray-400': isClearButtonDisabled,
                'text-bright-blue-600': !isClearButtonDisabled,
              }
            )}
          >
            <Icon name="ui/trashcan" size="12" className="mr-2" />
            <span>{t('club-editcontact.clear', 'Clear')}</span>
          </button>
        </div>
      )}
      <div className="tablet:flex mobile:flex mobile:flex-wrap">
        <div className="desktop:w-1/2 tablet:w-1/2 mobile:w-1/2">
          <CountryFlagSelect
            name={`${suffix}`}
            label={labels.phoneNumber}
            searchable
            required={required || isCountryFlagRequired}
            isCountryFlagRequired={isCountryFlagRequired}
          />
        </div>
        <div className="tablet:flex-1 desktop:w-1/2  tablet:w-1/2 mobile:w-1/2 pt-0.5">
          <TextField
            name={`${suffix}.number`}
            label=""
            type="tel"
            required={required}
            extraClasses="border-l-0 mt-5"
          />
        </div>
        {labels.phoneExtension && (
          <div className="tablet:flex-1 tablet:ml-2 tablet:w-1/8 mobile-w-full">
            <TextField
              name={`${suffix}.extension`}
              label={labels.phoneExtension}
              type="tel"
              required={false}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PhoneInput;
