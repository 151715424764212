import React, { useState } from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import Divider from '@components/Divider';
import SharingPermissionsAlerts from '@components/Formik/Select/SharingPermissionsAlerts';
import SharingPermissionSelect from '@components/Formik/Select/SharingPermissionSelect';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';
import FirstLastNameForm from '@presenters/web/components/Leads/Forms/FirstAndLastNameForm';
import ProfileEmailForm from '@presenters/web/components/Leads/Forms/ProfileEmailForm';
import AddressForm from '@presenters/web/pages/Profile/Edit/ContactInformation/AddressForm';
import PhoneForm from '@presenters/web/pages/Profile/Edit/ContactInformation/PhoneForm';
import { profileDetailsForRejoinValidationSchema } from '@presenters/web/pages/Profile/Edit/validationSchema';

import {
  FormItem,
  mapContactInformationDataToFormValues,
  mapFormValuesToUpdateContactInformationInput,
  ProfileInformationFormValues,
} from '@domain/profile';

import { useNotifications } from '@use-cases/notifications';

import { useFetchRejoinInfo } from '@repositories/leads/hooks/useFetchRejoinInfo';
import { useUpdateProfileInformation } from '@repositories/profile/hooks/useUpdateProfileInformation';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { useMobileLayout } from '@hooks/useMobileLayout';

import { EmailType } from '@typings/graphql';

const PersonalInformationEdit: React.FC = () => {
  const { user } = useAppConfig();
  const individualId = user?.individualId;
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { data, loading, refetch } = useFetchRejoinInfo(individualId || '');
  const { isMobileLayout } = useMobileLayout();

  const [updateProfileInformation] = useUpdateProfileInformation();

  if (loading || !data || !data.rejoinInfo) {
    return <Loading />;
  }
  const { rejoinInfo } = data;

  const values = mapContactInformationDataToFormValues(rejoinInfo);

  if (values.emails.length === 0) {
    values.emails.push({
      address: '',
      isPrimary: false,
      type: EmailType.Personal,
    });
  }

  const formValues = {
    ...values,
    firstName: rejoinInfo.firstName,
    lastName: rejoinInfo.lastName,
  };

  const handleFormSubmit = async (values: ProfileInformationFormValues) => {
    const mutationValues = mapFormValuesToUpdateContactInformationInput(values);

    await updateProfileInformation({
      variables: {
        id: individualId,
        firstName: values.firstName,
        lastName: values.lastName,
        ...mutationValues,
      },
    });
    await refetch();

    addSuccess(t('edit-personal-details.form.success', 'Update successful.'), {
      id: 'form.success',
    });

    localizedNavigate('/join-form/rejoin');
  };

  const handleCancel = () => {
    localizedNavigate('/join-form/rejoin');
  };

  const pageTitle = t(
    'edit-profile-information-page.title',
    'Edit profile information'
  );

  const personalInformation = t(
    'edit-profile-details.subheader-personal-information',
    'Personal information'
  );

  const backBtnTitle = t('feedback.back-link', 'Back');

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={formValues}
        validationSchema={profileDetailsForRejoinValidationSchema(t)}
        onSubmit={handleFormSubmit}
      >
        {({ values, isValid, setFieldValue }) => {
          const selectPrimary = (
            fieldName: keyof ProfileInformationFormValues
          ) => (selectedIndex: number) => {
            setFieldValue(
              fieldName,
              // @ts-ignore
              values[fieldName].map((item: FormItem, index: number) => {
                return index === selectedIndex
                  ? { ...item, isPrimary: true }
                  : { ...item, isPrimary: false };
              })
            );
          };
          return (
            <>
              <LinkPrevious path="/join-form/rejoin" label={backBtnTitle} />
              <Title className="mobile:mt-6 mb-0 text-gray-600">
                {pageTitle}
              </Title>
              <Form>
                <div className="desktop:flex-2 desktop:mr-24">
                  <div className="max-w-lg">
                    <h2
                      className={
                        isMobileLayout
                          ? 'h3 mt-6 mb-6 font-normal text-lg'
                          : 'desktop:mt-10'
                      }
                      data-testid="title"
                    >
                      {personalInformation}
                    </h2>
                    <FirstLastNameForm />
                  </div>
                </div>
                <Divider />
                <h2
                  className={
                    isMobileLayout ? 'h3 mb-6 text-lg' : 'desktop:mt-10 '
                  }
                >
                  {t('edit-personal-details.subheader-email', 'Email')}
                </h2>
                <div className="desktop:flex desktop:flex-row-reverse">
                  <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg mobile:mb-0">
                    {formValues.sharingPermissionsExtended && (
                      <>
                        <SharingPermissionSelect
                          selectName="sharingPermissionsExtended.email.id"
                          isClubOfficer={!!data?.rejoinInfo.isClubOfficer}
                        />
                        <SharingPermissionsAlerts
                          selectedSharingPermission={
                            values.sharingPermissionsExtended?.email
                          }
                          isClubOrDistrictOfficer={
                            !!data?.rejoinInfo.isClubOrDistrictOfficer
                          }
                        />
                      </>
                    )}
                  </div>
                  <div className="desktop:flex-2 desktop:mr-24">
                    <ProfileEmailForm
                      emails={values.emails}
                      selectPrimary={selectPrimary('emails')}
                    />
                  </div>
                </div>
                <Divider />
                <h2 className={isMobileLayout ? 'h3 text-lg' : ''}>
                  {t('edit-personal-details.subheader-phone', 'Phone')}
                </h2>
                <div className="desktop:flex desktop:flex-row-reverse">
                  <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg mobile:mb-0">
                    {formValues.sharingPermissionsExtended && (
                      <SharingPermissionSelect selectName="sharingPermissionsExtended.phone.id" />
                    )}
                  </div>
                  <div className="desktop:flex-2 desktop:mr-24">
                    <PhoneForm
                      phones={values.phones}
                      selectPrimary={selectPrimary('phones')}
                    />
                  </div>
                </div>
                <Divider />
                <h2 className={isMobileLayout ? 'h3 text-lg' : ''}>
                  {t('edit-personal-details.subheader-address', 'Address')}
                </h2>
                <div className="desktop:flex desktop:flex-row-reverse">
                  <div className="desktop:flex-1 mb-10 desktop:mb-0 max-w-lg mobile:mb-0">
                    {formValues.sharingPermissionsExtended && (
                      <SharingPermissionSelect selectName="sharingPermissionsExtended.address.id" />
                    )}
                  </div>
                  <div className="desktop:flex-2 desktop:mr-24">
                    <AddressForm
                      addresses={values.addresses}
                      selectPrimary={selectPrimary('addresses')}
                    />
                  </div>
                </div>
                {isMobileLayout ? <Divider /> : ''}
                <div className="max-w-lg">
                  <Button
                    full
                    className="desktop:mt-16 mb-6 mobile:mt-10"
                    disabled={!isValid}
                    clickHandler={() => {
                      if (!isValid)
                        addError(
                          t(
                            'edit-personal-details.form.error-msg',
                            'Please correct the invalid fields'
                          ),
                          { id: 'form.error' }
                        );
                    }}
                  >
                    {t(
                      'edit-personal-details.form.submit-label',
                      'Save Changes'
                    )}
                  </Button>
                  <Button
                    data-testid="button"
                    full
                    text
                    type="button"
                    clickHandler={() => setShowConfirmationModal(true)}
                  >
                    {t('edit-personal-details.form.cancel-label', 'Cancel')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      <LeaveFormConfirmationModal
        isOpen={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(false)}
        onConfirm={() => {
          setShowConfirmationModal(false);
          handleCancel();
        }}
      />
    </OneColumn>
  );
};
export default PersonalInformationEdit;
