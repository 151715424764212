import * as React from 'react';

import classNames from 'classnames';

import Tooltip from '../../Tooltip';

export const FormElementDescription: React.FC<{
  id: string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}> = ({ id, className, disabled, children }) => (
  <p
    id={`${id}-description`}
    className={classNames('text-xs mt-2 mb-0', className, {
      'text-gray-300': disabled,
      'text-gray-500': !disabled,
    })}
  >
    {children}
  </p>
);
export const FormElementErrorMessage: React.FC<{
  id: string;
  className?: string;
  children?: React.ReactNode;
}> = ({ id, className, children }) => (
  <p
    id={`${id}-error`}
    className={classNames('mt-3 mb-0 text-xs text-red-600', className)}
    data-cy="error-message"
  >
    {children}
  </p>
);

export const FormElementRequiredMarker: React.FC<{ required?: boolean }> = ({
  required,
}) => (required ? <span aria-hidden="true">*</span> : null);

export const LabelHelper: React.FC<{
  label: string;
  labelAfter?: boolean;
  labelHidden?: boolean;
  id: string;
  classes?: { label?: string; elementWrapper?: string };
  prefix?: React.ReactNode;
  children: React.ReactElement;
  suffix?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  error?: boolean;
  tooltipText?: string;
  isFontBold?: boolean;
}> = ({
  label,
  labelAfter,
  labelHidden,
  id,
  classes,
  prefix,
  children,
  suffix,
  required,
  disabled,
  description,
  error,
  tooltipText,
  isFontBold,
}) => {
  // Apply an aria-describedby to the child form element.
  const describedBy = classNames({
    [`${id}-description`]: description,
    [`${id}-error`]: error,
  });
  let element = describedBy
    ? React.cloneElement(React.Children.only(children), {
        'aria-describedby': describedBy,
      })
    : children;

  // Apply a wrapper around the form element and its prefix and suffix.
  if (classes?.elementWrapper) {
    element = (
      <span className={classes?.elementWrapper}>
        {prefix}
        {element}
        {suffix}
      </span>
    );
  } else {
    element = (
      <>
        {prefix}
        {element}
        {suffix}
      </>
    );
  }

  return (
    <>
      {labelAfter && element}
      <div
        className={classNames('items-center', {
          'flex mb-3': !labelAfter,
          'inline-flex': labelAfter,
        })}
      >
        <label
          htmlFor={id}
          className={classNames(classes?.label, 'text-small', {
            'font-bold': isFontBold,
            'sr-only': labelHidden,
            'text-gray-300': disabled,
            'text-gray-600': !disabled,
          })}
        >
          <span dangerouslySetInnerHTML={{ __html: label }} />
          <FormElementRequiredMarker required={required} />
        </label>
        {tooltipText && <Tooltip content={tooltipText} />}
      </div>
      {!labelAfter && element}
    </>
  );
};

/**
 * A wrapper to be used by form elements to get standardized label, description
 * and error messages.
 *
 * This component also contains the `LabelHelper`, `FormElementRequiredMarker`,
 * `FormElementDescription`, and `FormElementErrorMessage` components that
 * should only be used if `FormElement` component cannot be used, e.g. radio
 * buttons.
 */
export const FormElement: React.FC<{
  label: string;
  labelAfter?: boolean;
  labelHidden?: boolean;
  id: string;
  prefix?: React.ReactNode;
  children: React.ReactElement;
  suffix?: React.ReactNode;
  className?: string;
  classes?: {
    label?: string;
    elementWrapper?: string;
    description?: string;
    errorMessage?: string;
  };
  noMargins?: boolean;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  error?: boolean;
  errorMessage?: React.ReactNode;
  tooltipText?: string;
}> = ({
  label,
  labelAfter,
  labelHidden,
  id,
  prefix,
  children,
  suffix,
  className,
  classes,
  noMargins,
  required,
  disabled,
  description,
  error,
  errorMessage,
  tooltipText,
}) => (
  <div
    data-label="FormElement"
    className={classNames(`form-element ${className}`, { 'mb-6': !noMargins })}
  >
    <LabelHelper
      label={label}
      labelAfter={labelAfter}
      labelHidden={labelHidden}
      id={id}
      classes={{
        label: classes?.label,
        elementWrapper: classes?.elementWrapper,
      }}
      prefix={prefix}
      suffix={suffix}
      required={required}
      disabled={disabled}
      description={description}
      error={error}
      tooltipText={tooltipText}
    >
      {children}
    </LabelHelper>

    {description && (
      <FormElementDescription
        id={id}
        disabled={disabled}
        className={classes?.description}
      >
        {description}
      </FormElementDescription>
    )}
    {error && (
      <FormElementErrorMessage id={id} className={classes?.errorMessage}>
        {errorMessage}
      </FormElementErrorMessage>
    )}
  </div>
);
