import * as Yup from 'yup';
import moment from 'moment';
import { TFunction } from 'i18next';
import { isRequired } from '../validation/schemaDefinitions';

const dateOfBirthSchema = (t: TFunction) => {
  const currentDate = new Date();

  return Yup.object().shape(
    {
      dayOfBirth: Yup.number()
        .min(1, t('form.field.invalid_day', 'Invalid day specified'))
        .when(
          ['monthOfBirth', 'yearOfBirth'],
          (monthOfBirth: string, yearOfBirth: string, schema: any) => {
            const maxDays = (daysInMonth: number) =>
              schema.max(
                daysInMonth,
                t('form.field.invalid_day', 'Invalid day specified')
              );

            if (monthOfBirth && yearOfBirth) {
              const daysInMonth = moment(
                `${yearOfBirth}-${monthOfBirth}`
              ).daysInMonth();

              return maxDays(daysInMonth).required(
                t('form.field.error_required', 'Required')
              );
            }

            if (monthOfBirth) {
              const daysInSelectedMonth = moment()
                .month(Number(monthOfBirth) - 1)
                .daysInMonth();

              return maxDays(daysInSelectedMonth).required(
                t('form.field.error_required', 'Required')
              );
            }

            return maxDays(31);
          }
        )
        .integer(),
      monthOfBirth: Yup.string().when(
        'dayOfBirth',
        (dayOfBirth: string | number, schema: any) => {
          if (dayOfBirth) {
            return isRequired(t, schema);
          }

          return schema;
        }
      ),
      yearOfBirth: Yup.number()
        .max(
          currentDate.getFullYear(),
          t('form.field.invalid_year', 'Invalid year specified')
        )
        .min(
          currentDate.getFullYear() - 100,
          t('form.field.invalid_year', 'Invalid year specified')
        )
        .when(
          ['dayOfBirth', 'monthOfBirth'],
          (dayOfBirth: number, monthOfBirth: string, schema: any) => {
            if (dayOfBirth || monthOfBirth) {
              return isRequired(t, schema);
            }

            return schema;
          }
        )
        .integer(),
    },
    [
      ['dayOfBirth', 'monthOfBirth'],
      ['monthOfBirth', 'dayOfBirth'],
      ['dayOfBirth', 'yearOfBirth'],
      ['yearOfBirth', 'dayOfBirth'],
    ]
  );
};

export default dateOfBirthSchema;
