import i18n from 'i18next';
import { object, string } from 'yup';

import { Gender } from '@typings/graphql';

const { t } = i18n;

const genderSchema = object({
  gender: string()
    .nullable()
    .required(t('form.field.error_required', 'Required')),
  genderDescription: string()
    .nullable()
    .when('gender', {
      is: Gender.Self,
      then: string()
        .trim()
        .required(t('form.field.error_required', 'Required'))
        .max(
          255,
          t(
            'form.field.error_too_long',
            'Field cannot exceed {{number}} characters',
            { number: 255 }
          )
        ),
    }),
});

export default genderSchema;
