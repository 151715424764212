// Libs
import React from 'react';

// Components
import TextField from '@components/Formik/TextField';
// Hooks
// Utils
import { getEmailFieldName } from '@presenters/web/pages/Profile/Edit/ContactInformation/utils';

import { useTranslation } from '@external/react-i18next';

interface EmailFieldProps {
  index: number;
  isSignin?: boolean | null;
  address: string;
}

const EmailField: React.FC<EmailFieldProps> = ({
  index,
  isSignin,
  address,
}) => {
  const { t } = useTranslation();
  if (isSignin) {
    return (
      <div>
        <p className="h5 text-gray-400">
          {t('edit-contact-information.email.form.address-label', 'Email')}*
        </p>
        <p className="text-gray-400">{address}</p>
        <p className="text-gray-500 text-sm my-2">
          {t(
            'edit-profile-view.primary.email.message',
            `This is your My Rotary account sign-in email address. You
    can only edit or change this email by going to Account
    Settings.`
          )}
        </p>
      </div>
    );
  }
  return (
    <div>
      <TextField
        name={getEmailFieldName(index, 'address')}
        label={t('edit-contact-information.email.form.address-label', 'Email')}
        required
      />
    </div>
  );
};

export default EmailField;
