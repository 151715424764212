import { TFunction } from 'i18next';

import { getListProfessionSource } from '@domain/leads/join-form/professionList';

import { ProfessionSource } from '../join-form/types';

export const getPreferencesOptions = (t: TFunction) => {
  return [
    {
      value: 'own',
      label: t(
        'join-form-referral.field_preference_option_own',
        'I want to refer this person to my club'
      ),
    },
    {
      value: 'another',
      label: t(
        'join-form-referral.field_preference_option_another',
        'I want to refer this person to another club'
      ),
    },
  ];
};

export const getClubTypeOptions = (t: TFunction) => {
  return [
    {
      value: 'rotary',
      label: t('join-form-referral.field_club_type_option_rotary', 'Rotary'),
    },
    {
      value: 'rotaract',
      label: t(
        'join-form-referral.field_club_type_option_rotaract',
        'Rotaract'
      ),
    },
  ];
};

export const getProfessionOptions = (t: TFunction) => {
  const options: ProfessionSource[] = getListProfessionSource(t);
  return options.map(({ name, id }) => ({ label: name, value: id }));
};
