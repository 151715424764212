import React from 'react';

import { FieldArray } from 'formik';

import EmailField from '@presenters/web/components/Leads/Forms/ProfileEmailForm/EmailField';
import AddAnotherButton from '@presenters/web/pages/Profile/Edit/components/ButtonAddAnother';
import DeleteButton from '@presenters/web/pages/Profile/Edit/components/ButtonDelete';
import PrimaryRadio from '@presenters/web/pages/Profile/Edit/components/PrimaryRadio';
import { getNewEmail } from '@presenters/web/pages/Profile/Edit/ContactInformation/utils';

import { ContactInformationFormValues } from '@domain/profile';

import { useTranslation } from '@external/react-i18next';

interface Props {
  selectPrimary: (selectedIndex: number) => void;
  emails: ContactInformationFormValues['emails'];
}

const Form: React.FC<Props> = ({ emails, selectPrimary }) => {
  const { t } = useTranslation();

  return (
    <div className="max-w-lg">
      <FieldArray
        name="emails"
        render={({ push, remove }) => {
          return (
            <>
              {emails.map((email, index) => {
                const customKey = `email${index}`;
                return (
                  <div
                    className="mt-20 first:mt-0 mb-8 mobile:mt-6"
                    key={customKey}
                  >
                    <EmailField
                      index={index}
                      isSignin={email.isSignin}
                      address={email.address}
                    />
                    <div className="flex justify-between mt-4">
                      <PrimaryRadio
                        checked={email.isPrimary}
                        id={`primary-email-radio-${index}`}
                        onClick={() => selectPrimary(index)}
                        label={t(
                          'edit-contact-information.email.primary-label',
                          'Primary Email for RI communication'
                        )}
                      />
                      {!email.isPrimary && !email.isSignin && (
                        <DeleteButton onClick={() => remove(index)} />
                      )}
                    </div>
                  </div>
                );
              })}
              <AddAnotherButton
                label={t(
                  'edit-contact-information.email.add-label',
                  'Add another email'
                )}
                onClick={() => push(getNewEmail())}
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default Form;
