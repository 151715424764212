import React from 'react';
import Modal from 'react-modal';
import { Button } from '@components/Button';

const customStyles = {
  content: {
    maxWidth: '550px',
    maxHeight: '420px',
    margin: 'auto',
    padding: '45px',
    overflow: 'visible',
  },
  overlay: {
    zIndex: 99,
  },
};

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  title: string;
  contentLabel: string;
  text: string;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
}

const BaseModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  onConfirm,
  title,
  contentLabel,
  text,
  confirmButtonLabel,
  cancelButtonLabel,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel={contentLabel}
      style={customStyles}
      ariaHideApp={false}
    >
      <h2 className="text-center break-words">{title}</h2>
      <p className="text-xs text-center">{text}</p>
      <div className="text-center mt-10 mb-12">
        <Button
          clickHandler={onConfirm}
          className="mb-4 tablet:min-w-16 mobile-m:w-full"
          full
        >
          {confirmButtonLabel}
        </Button>
        <Button
          secondary
          clickHandler={closeModal}
          className="tablet:min-w-16 mobile-m:w-full"
          full
        >
          {cancelButtonLabel}
        </Button>
      </div>
    </Modal>
  );
};

export default BaseModal;
