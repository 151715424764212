import { useMutation } from '@apollo/client';

import { updateProfileInformationMutation } from '../queries';

import { UpdateContactInformationMutation } from '@typings/operations';

export const useUpdateProfileInformation = () => {
  return useMutation<UpdateContactInformationMutation>(
    updateProfileInformationMutation
  );
};
